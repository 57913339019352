/**
 * Main core
 * @namespaces
 * @property {function} ClientCore.variables — build variables;
 * @property {object}   ClientCore.logging   — message logging.
 */
 const ClientCore = {

    variables: (variable, defaultClass) => {

        let result = null;
        
        if (variable == undefined) 		  result = Array.from(document.querySelectorAll(defaultClass));
        if (typeof variable == 'string')  result = [document.querySelector(variable)] || false;	
        if (typeof variable == 'object')  result = (variable.length !== undefined) ? Array.from(variable) : [variable] || false
    
        return result;
    
    },

    logging: {
        'error': {
            'missing': `\n *Eng: "Element missing"\n *Rus: "Отсутствует элемент"`,    
            'type': `\n *Eng: "Invalid input value. Accepts values of type String or Object"\n *Rus: "Неправильное входное значение. Принимает значения типа String или Object"`,
        }
    }

}


window.ClientCore = ClientCore;