(function () {
    'use strict';

    /**
     * Main core
     * @namespaces
     * @property {function} ClientCore.variables — build variables;
     * @property {object}   ClientCore.logging   — message logging.
     */
     const ClientCore$1 = {

        variables: (variable, defaultClass) => {

            let result = null;
            
            if (variable == undefined) 		  result = Array.from(document.querySelectorAll(defaultClass));
            if (typeof variable == 'string')  result = [document.querySelector(variable)] || false;	
            if (typeof variable == 'object')  result = (variable.length !== undefined) ? Array.from(variable) : [variable] || false;
        
            return result;
        
        },

        logging: {
            'error': {
                'missing': `\n *Eng: "Element missing"\n *Rus: "Отсутствует элемент"`,    
                'type': `\n *Eng: "Invalid input value. Accepts values of type String or Object"\n *Rus: "Неправильное входное значение. Принимает значения типа String или Object"`,
            }
        }

    };


    window.ClientCore = ClientCore$1;

    const MethodMap = (() => {

        class Core {

            constructor(el, options) {

                this.$el = el;
                this.$params = options;
                this.$routeMap = {};
                this.$collectionsCoord = [];
                this.$suggestElement = this.$params['suggest'] || null;
                this.$callback = this.$params['callback'] || {};
                this.$script = document.getElementById('ya-map') || false;

            }

            
            init() {
                
                this.start();
                this.$ListenerWindowScroll = () => this.start();
                window.addEventListener('scroll', this.$ListenerWindowScroll, false);

            }


            start() {

                if (window.outerHeight > Math.abs(this.$el.getBoundingClientRect().top)) {

                    let outputContainer = document.body || document.head;
        
        
                    if (this.$script == false) {
        
                        this.$script = document.createElement('script');
                        this.$script.setAttribute('id', 'ya-map');
                        this.$script.setAttribute('type', 'text/javascript');
                        this.$script.setAttribute('src', 'https://api-maps.yandex.ru/2.1/?apikey=8c91a3d6-774c-450e-8457-ddb6966d8b8d&lang=ru_RU');
            
                        outputContainer.appendChild(this.$script);

                        this.$script.onload = () => {
                        
                            this.variables();
                            this.build();
                        
                        };
        
                    } else {

                        this.variables();
                        this.build();                    

                    }


                    window.removeEventListener('scroll', this.$ListenerWindowScroll, false);
                
                }

            }


            variables() {

                let behaviors = false;

                // behaviors
                if (this.$params['behaviors']) {

                    behaviors = {
                        disable:   this.$params['behaviors']['disable']   || false,
                        enabled:   this.$params['behaviors']['enabled']   || false,
                        get:       this.$params['behaviors']['get']       || false,
                        isEnabled: this.$params['behaviors']['isEnabled'] || false
                    };

                }

                this.$params = {
                    zoom:      this.$params['zoom']     || 15,
                    center:    this.$params['center']   || [61.698394, 99.502091],
                    controls:  this.$params['controls'] || [],
                    objects:   this.$params['objects']  || {
                        "type": "FeatureCollection",
                        "features": [{
                            "id": 0,
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": [61.698394, 99.502091],
                            },
                        }],
                    },
                    behaviors: behaviors,
                };


            }


            build() {

                ymaps.ready(() => {

                    this.$map = new ymaps.Map(this.$el, {
                        zoom: this.$params['zoom'],
                        center: this.$params['center'],
                        controls: this.$params['controls']
                    });
                                    
                    this.$objectManager = new ymaps.ObjectManager({                
                        clusterize: true, // Чтобы метки начали кластеризоваться, выставляем опцию.
                        gridSize: 128,
                    });

                    // Создаем выпадающую панель с поисковыми подсказками и прикрепляем ее к HTML-элементу по его id.
                    if (this.$suggestElement !== null) {

                        this.$suggestView = new ymaps.SuggestView(this.$suggestElement);
                    
                    }

                    this.map_custom_controls();
                    this.map_behaviors();
                    this.map_geoObjects();
                    this.map_scale();
                    this.map_events();

                    
                    this.$callback(this);
                                    
                });

            }


            map_custom_controls() {

                // Создадим пользовательский макет ползунка масштаба.
                let ZoomLayout = ymaps.templateLayoutFactory.createClass(
                    `<div class="method-map-zoom">
                    <div class="method-map-zoom__wrapper">
                        <button class='method-map-zoom__trigger --zoom-in'><i class='icon-plus'></i></button>
                        <button class='method-map-zoom__trigger --zoom-out'><i class='icon-minus'></i></button>
                    </div>
                </div>`, {

                    // Переопределяем методы макета, чтобы выполнять дополнительные действия
                    // при построении и очистке макета.
                    build: function () {

                        // Вызываем родительский метод build.
                        ZoomLayout.superclass.build.call(this);

                        // Привязываем функции-обработчики к контексту и сохраняем ссылки
                        // на них, чтобы потом отписаться от событий.
                        this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
                        this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

                        // Начинаем слушать клики на кнопках макета.
                        document.querySelector('.--zoom-in').addEventListener('click',  this.zoomInCallback, false);
                        document.querySelector('.--zoom-out').addEventListener('click', this.zoomOutCallback, false);
                    
                    },

                    clear: function () {

                        // Снимаем обработчики кликов.
                        document.querySelector('.--zoom-in').removeEventListener('click',  this.zoomInCallback, false);
                        document.querySelector('.--zoom-out').removeEventListener('click', this.zoomOutCallback, false);

                        // Вызываем родительский метод clear.
                        ZoomLayout.superclass.clear.call(this);
                    
                    },

                    zoomIn: function () {

                        let map = this.getData().control.getMap();                    
                        map.setZoom(map.getZoom() + 1, {checkZoomRange: true});
                    
                    },

                    zoomOut: function () {

                        let map = this.getData().control.getMap();
                        map.setZoom(map.getZoom() - 1, {checkZoomRange: true});
                    
                    }
                }),

                zoomControl = new ymaps.control.ZoomControl({options: {layout: ZoomLayout}});
                this.$map.controls.add(zoomControl);

            }


            map_behaviors() {

                // behaviors
                if (this.$params['behaviors'] !== false) {

                    // disable
                    if (this.$params['behaviors']['disable'] !== false) {

                        this.$map.behaviors.disable(this.$params['behaviors']['disable']);

                    }

                }
                
            }


            /**
             * Нанесём геообъекты на карту
             */
            map_geoObjects() {                        

                if (this.$objectManager) {

                    this.$objectManager.removeAll();
                    this.$objectManager.add(this.$params['objects']);            
        
                    this.$map.geoObjects.add(this.$objectManager);
                    this.$collectionsCoord = this.$map.getBounds();
                
                }
                            
            }
            
            
            /**
             * Масштабируем карту так, чтобы в области видимости были все метки
             */
            map_scale() {

                this.$map.container.fitToViewport();
                this.$map.setBounds(this.$map.geoObjects.getBounds(), { 

                    useMapMargin: true,
                    checkZoomRange: true,
                
                }).then(() => { 
                        
                    /* 
                        своего рода костыль, когда на карте присутствует только одна метка, 
                        то яндекс.карты максимально зумируют                     
                    */ 
                    if (this.$map.getZoom() >= 21) {
                        
                        this.$map.setZoom(15);
                    
                    }
                
                });
            
            }


            /**
             * Все события карты 
             */        
            map_events() {
                
                this.$objectManager.objects.events.add('click', (event) => {

                    let id = event.get('objectId'),
                        obj = this.$objectManager.objects.getById(id),
                        coords = obj.geometry.coordinates;
                    

                    // при клике на метку, зумируем карту
                    this.$map.setCenter(coords, 16, { checkZoomRange: true });

                });
            
            }


            /**
             * Метод: Обновляем объекты карты
             */
            updateGeoObjects(objects) {

                try {

                    this.$params['objects'] = objects;
                    
                    this.map_geoObjects();
                    this.map_scale();
                    
                } catch (error) {

                    console.error(`#MethodMap: updateGeoObjects. \nMessage: ${error.message} \nElement: `, this.$el);

                }

            }


            /**
             * Метод: Устанавливает центр и коэффициент масштабирования карты
             * @param {array} coord - координаты
             */
            setCenter(coord) {

                try {

                    this.$map.setCenter(coord, 16, { checkZoomRange: true });
                
                } catch (error) {
                    
                    console.error(`#MethodMap: setCenter. \nMessage: ${error.message} \nElement: `, this.$el);

                }

            }


            /**
             * Метод: Строит маршрут на карте
             * @param {array} points   - точки маршрута(https://yandex.ru/dev/maps/jsapi/doc/2.1/dg/concepts/router/multiRouter.html#multiRouter__how-to-build-route)
             * @param {object} options - настройки маршрута(https://yandex.ru/dev/maps/jsapi/doc/2.1/dg/concepts/router/multiRouter.html#multiRouter__route-settings) 
             */
            route(config) {

                this.$routePoints = config['points']     || [];
                this.$routeParams = config['params']     || {};
                this.$routeCallback = config['callback'] || {};


                try {                

                    // создание экземпляра маршрута.
                    let multiRoute = new ymaps.multiRouter.MultiRoute({

                        referencePoints: this.$routePoints,
                        params: this.$routeParams
                    
                    }, {

                        // внешний вид путевых точек.
                        wayPointStartIconFillColor: "#fcb629",
                        wayPointFinishIconFillColor: "#b03e90",

                        // внешний вид линии активного маршрута
                        routeActiveStrokeWidth: 4,
                        routeActiveStrokeStyle: 'solid',
                        routeActiveStrokeColor: "#b03e90",
                    
                        // автоматически устанавливать границы карты так,
                        // чтобы маршрут был виден целиком.
                        boundsAutoApply: true
                    
                    });
                
                    
                    // добавление маршрута на карту.
                    this.$map.geoObjects.removeAll();
                    this.$map.geoObjects.add(multiRoute);
                    
                    
                    // событие: requestsuccess
                    multiRoute.model.events.add('requestsuccess', () => {

                        let active = multiRoute.getActiveRoute();
                        let jsonRoute = multiRoute.model.getJson();
                        let waypoints = jsonRoute.properties.waypoints;
                        
                        // запишем характеристики маршрута(дистанция и длительность)
                        if (active !== null) {
                            
                            this.$routeMap.distance = active.properties.get('distance');
                            this.$routeMap.duration = active.properties.get('duration');

                        } else {

                            this.$routeMap.distance.text = 'Неизвестно';
                            this.$routeMap.duration.text = 'Неизвестно';

                        }
                        
                        // запишем координаты и вызовем callback
                        this.$collectionsCoord = waypoints.map((value) => value.coordinates.reverse());                    
                        this.$routeCallback(this);

                    });

                } catch (error) {
                    
                    console.error(`#MethodMap: route. \nMessage: ${error.message} \nElement: `, this.$el);

                }
            
            }

        }


        let active = null;


    	/**
    	 * Map initialization
    	 * @param {object} config - map parameters 
    	 */
        const init = (config) => {

            let options =  config.options || {},
                elements = ClientCore.variables(config.element);

                
            try {

    			if (elements == false && config.element !== undefined) throw new Error(ClientCore.logging['error']['missing']);
    			if (elements == null  && config.element !== undefined) throw new Error(ClientCore.logging['error']['type']);                        


                elements.forEach((value) => {

                    active = new Core(value, options);
                    active.init();

                });

                
                return active;

            } catch(error) {

    			console.error(`#MethodMap: init. \nMessage: ${error.message} \nElement: `, config.element);

    		}

        };


        return { init };

    })();


    window.MethodMap = MethodMap;

    const BlockHeader = (() => {

        class Core {

            constructor() {

                this.$el = document.querySelector('.header') || false;
                
                if (this.$el !== false) {

                    this.init();

                }

            }
            
            
            init() {
                
                this.$hamburger = this.$el.querySelector('.header__hamburger');
                this.$menu = this.$el.querySelector('.header__menu');
                this.$hamburger.addEventListener('click', this.listenerHamburgerClick.bind(this), false);

            }


            listenerHamburgerClick() {

                this.$menu.classList.toggle('--show');            
                this.$hamburger.classList.toggle('--active');

                document.body.classList.toggle('overflow-hidden');

            }
        
        }


        let active = null;


        const init = () => {

            active = new Core();

            return active;
            
        };


        return { init };

    })();


    window.BlockHeader = BlockHeader;

    // init
    document.addEventListener('DOMContentLoaded', () => {

        BlockHeader.init();

    });

})();
