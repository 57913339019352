import './modules/core.js';
import './modules/methods/map.js';

// blocks
import { BlockHeader } from './modules/blocks/header.js';


// init
document.addEventListener('DOMContentLoaded', () => {

    BlockHeader.init();

})