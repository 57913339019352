/**
 * Basic UI: Dropdown.
 */
const BasicDropdown = (() => {

    class Core {

        constructor(element) {

            this.$el = element;
            this.$menu = this.$el.querySelector('.dropdown-menu');            
            this.$trigger = this.$el.querySelector('.dropdown-trigger');            
            
            this.variables();
            this.build();
            this.event();                         
        
        }


        variables() {

            let dataOptions = JSON.parse(this.$el.getAttribute('data-dropdown-options')) || {};
            
            // options            
            this.$options = {                
                event:    (dataOptions['event']    || 'click'),       
                position: (dataOptions['position'] || 'bottom'),
                className: {
                    selected: 'dropdown--selected'
                }            
            };

            // custom event
            this.$customEventToggle = new CustomEvent('dropdown toggle', {
				bubbles: true,
				detail: {
                    'trigger': null,
                    'menu': null,
                    'hidden': true
				}
			});
                        
            this.funcClickByArea = (event) => {

                let target = event.target;

                if (target.closest('.dropdown-menu') == null && target.closest('.dropdown-trigger') == null) {
                    
                    this.$trigger.setAttribute('aria-expanded', false);
                    this.$el.classList.remove(this.$options.className.selected);

                    this.position();
                    document.removeEventListener('click', this.funcClickByArea, false);

                }

            }

        }


        build() {

            if (this.$el.classList.contains('dropdown-init') == false) {

                this.$el.classList.add('dropdown');
                this.$el.classList.add('dropdown-init');
                this.$options.index = Array.from(document.querySelectorAll('.dropdown-init')).indexOf(this.$el);
                            
                let id = this.$trigger.getAttribute('id') || `dropdown-trigger-${this.$options.index}`;

                this.$trigger.setAttribute('id', id);
                this.$trigger.setAttribute('aria-haspopup', true);
                this.$trigger.setAttribute('aria-expanded', false);

                
                this.position();            
                this.$menu.setAttribute('aria-labelledby', this.$trigger.getAttribute('id'));                
            
            }

        }


        position() {
            
            let position = 'bottom',
                coordMenu = this.$menu.getBoundingClientRect(),                
                coordTrigger = this.$trigger.getBoundingClientRect();
 

            // init position dropdown
            if (this.$options['position'] == 'top')    position = `bottom: ${coordTrigger['height']}px;`; 
            if (this.$options['position'] == 'right')  position = `top: 0px; right: -${coordMenu['width']}px;`; 
            if (this.$options['position'] == 'bottom') position = `top: ${coordTrigger['height']}px`;                
            if (this.$options['position'] == 'left')   position = `top: 0px; left: -${coordMenu['width']}px;`; 
            
            this.$menu.setAttribute('style', position);
                
                
            // check and edit position dropdown menu
            if (this.$options['position'] == 'top'    && coordMenu['top'] < 0) position = `top: ${coordTrigger['height']}px; left: 0px;`;
            if (this.$options['position'] == 'right'  && coordMenu['right'] > window.innerWidth) position = `top: ${coordTrigger['height']}px; left: 0px;`;
            if (this.$options['position'] == 'bottom' && coordMenu['bottom'] > document.documentElement.scrollHeight) position = `bottom: ${coordTrigger['height']}px;  left: 0px;`;
            if (this.$options['position'] == 'left'   && coordMenu['left'] < 0) position = `top: ${coordTrigger['height']}px; left: 0px;`; 

            this.$menu.setAttribute('style', position);
        
        }


        event() {

            this.$trigger.addEventListener(this.$options['event'], this.listener.bind(this), false);

        }


        listener() {
                
            this.position();
            this.$el.classList.toggle(this.$options.className.selected);
            this.$trigger.setAttribute('aria-expanded', this.$trigger.getAttribute('aria-expanded') == 'false');                        
 
            if (this.$el.classList.contains(this.$options.className.selected) == true) {

                document.addEventListener('click', this.funcClickByArea, false);

            }

            // custom event: dropdown switch
			this.$customEventToggle.detail.trigger = this.$trigger;
			this.$customEventToggle.detail.menu = this.$menu;
			this.$customEventToggle.detail.hidden = !this.$el.classList.contains(this.$options.className.selected);
			this.$trigger.dispatchEvent(this.$customEventToggle);   
            
        }


        method_show() {

            this.position();
            this.$trigger.setAttribute('aria-expanded', true);
            this.$el.classList.add(this.$options.className.selected);

            document.addEventListener('click', this.funcClickByArea, false);

        }


        method_hide() {

            this.position();
            this.$trigger.setAttribute('aria-expanded', false);
            this.$el.classList.remove(this.$options.className.selected);

            document.removeEventListener('click', this.funcClickByArea, false);

        }

    };


    let active = null;


    // init
    const init = (variable) => {

        let element = null,
            elements = BasicCore.variables(variable, '.js-dropdown:not(.dropdown-init)');            


		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            
            elements.forEach((value) => {  

                element = value;                
                active = new Core(element);                
            
            });

            return active;
        
        } catch(error) {

			console.error(`${BasicCore.logging['name']} Dropdown init. \nMessage: ${error.message} \nElement: `, element);

		}

    }


    // show
    const show = (variable) => {

        let element = (typeof variable == 'object') ? variable : document.querySelector(variable)            


		try {

			if (element == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (element == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            
            active = new Core(element);
            active.method_show();

            return active;
        
        } catch(error) {

			console.error(`${BasicCore.logging['name']} Dropdown show. \nMessage: ${error.message} \nElement: `, element);

		}

    }


    // hide
    const hide = (variable) => {

        let element = (typeof variable == 'object') ? variable : document.querySelector(variable)            


		try {

			if (element == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (element == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            
            active = new Core(element);
            active.method_hide();

            return active;
        
        } catch(error) {

			console.error(`${BasicCore.logging['name']} Dropdown hide. \nMessage: ${error.message} \nElement: `, element);

		}

    }


    return { init, show, hide };

})()


window.BasicDropdown = BasicDropdown;


export { BasicDropdown };