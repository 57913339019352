const BlockHeader = (() => {

    class Core {

        constructor() {

            this.$el = document.querySelector('.header') || false;
            
            if (this.$el !== false) {

                this.init();

            }

        }
        
        
        init() {
            
            this.$hamburger = this.$el.querySelector('.header__hamburger');
            this.$menu = this.$el.querySelector('.header__menu');
            this.$hamburger.addEventListener('click', this.listenerHamburgerClick.bind(this), false);

        }


        listenerHamburgerClick() {

            this.$menu.classList.toggle('--show');            
            this.$hamburger.classList.toggle('--active');

            document.body.classList.toggle('overflow-hidden');

        }
    
    }


    let active = null;


    const init = () => {

        active = new Core();

        return active;
        
    }


    return { init };

})();


window.BlockHeader = BlockHeader;


export { BlockHeader }